const Contact = () => {
  const handleEmailClick = () => {
    const recipient = "info@sevenstarsre.com"; // Replace with the recipient's email address
    const subject = "Subject of the email"; // Replace with the desired subject
    const body = "Content of the email"; // Replace with the desired email body

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };
  return (
    <section className="contact">
      <div className="page-top-contact">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex justify-content-center align-items-center"
              style={{ height: 600 }}>
              <h1
                className="page-title"
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "50px",
                  textShadow: "0px 4px 10px #000",
                }}>
                CONTACT US
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-envelope"></i>
                    <h5>Email</h5>
                    <h6 onClick={handleEmailClick}>
                      {" "}
                      info@sevenstarsre.com{" "}
                    </h6>
                    <p></p>
                    <p></p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-map-marker-alt"></i>
                    <h5>Address</h5>
                    <h6>
                    Block no. 320, way no. 63, building no. 838, Al Hail North Muscat
                    </h6>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="contact-item">
                    <i className="fas fa-phone-alt"></i>
                    <h5>Phone</h5>
                    <h6> 94330195</h6>
                    <p></p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
