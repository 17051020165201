import { useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { useLocation } from "react-router-dom";

const FlatDetail = () => {
  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const receivedData = location.state?.data;
  let updated = receivedData.images.map((item) => ({
    original: item,
    thumbnail: item,
  }));
  const images = updated;
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="flat-detail">
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="fd-top flat-detail-content">
              <div>
                <h3
                  style={{
                    fontSize: fullScreen ? "20px" : "42px",
                    fontWeight: "bold",
                  }}>
                  {receivedData.title}
                </h3>
                <p className="fd-address">
                  {" "}
                  <i className="fas fa-map-marker-alt"></i>
                  {receivedData.address}
                </p>
              </div>
              <div>
                <span
                  style={{
                    fontSize: fullScreen ? "16px" : "30px",
                    fontWeight: "bold",
                  }}>
                  OMR {receivedData.price}
                </span>
              </div>
            </div>
            <ImageGallery
              flickThreshold={0.5}
              slideDuration={0}
              items={images}
              showNav={false}
              showFullscreenButton={false}
              showPlayButton={false}
            />
            <div className="row">
              <div className="col-lg-8">
                <div className="fd-item">
                  <h4>Description</h4>
                  <p>{receivedData.description}</p>
                </div>
                <div className="fd-item fd-property-detail">
                  <h4>Property Details</h4>
                  <div className="row">
                    <div className="col-lg-4">
                      <span>Bedrooms: </span>
                      <span>{receivedData.rooms}</span>
                    </div>
                    {receivedData.features.map((item) => (
                      <div className="col-lg-4">
                        <span>{item.name}: </span>
                        <span>{item.quantity}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="fd-item fd-features">
                  <h4>Features</h4>
                  <div className="row">
                    {receivedData.features.map((item) => (
                      <div className="col-lg-4">
                        <i className="fa fa-check"></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="fd-sidebar-item">
                  <h4>Category</h4>
                  <ul className="category-ul">
                    <li>{receivedData.housetype}</li>
                    <li>
                      {receivedData.transaction === "rent" ? "Rent" : "Sale"}
                    </li>
                    <li>
                      {receivedData.furnished ? "Furnished" : "Not Furnished"}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlatDetail;
