import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openModal: true,
};

export const Slice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    handleModal: (state) => {
      state.openModal = !state.openModal;
    },
  },
});

export const { handleModal } = Slice.actions;

export default Slice.reducer;
