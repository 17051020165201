import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Title from "./Title";
import BestFlatItem from "./BestFlatItem";
import { getProperty } from "../apiservices";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
const array = [
  {
    title: "Sector: 1, Unit Name/Type: Ras Asaylah",
    desc: "This lovely 2 Bedroom Ras Asaylah townhouse is located near the marina and Kempinski Hotel/on Marina Drive. ",
    features: [
      {
        name: "bedrooms",
        qty: 2,
      },
      {
        name: "bathrooms",
        qty: 1,
      },
    ],
    price: " $650",
    flag: "For Rent",
  },
  {
    title: "Sector: 1, Unit Name/Type: Ras Asaylah",
    desc: "This lovely 2 Bedroom Ras Asaylah townhouse is located near the marina and Kempinski Hotel/on Marina Drive. ",
    features: [
      {
        name: "bedrooms",
        qty: 2,
      },
      {
        name: "bathrooms",
        qty: 1,
      },
    ],
    price: " $650",
    flag: "For Sale",
  },
  {
    title: "Sector: 1, Unit Name/Type: Ras Asaylah",
    desc: "This lovely 2 Bedroom Ras Asaylah townhouse is located near the marina and Kempinski Hotel/on Marina Drive. ",
    features: [
      {
        name: "bedrooms",
        qty: 2,
      },
      {
        name: "bathrooms",
        qty: 1,
      },
    ],
    price: " $650",
    flag: "For Rent",
  },
  {
    title: "Sector: 1, Unit Name/Type: Ras Asaylah",
    desc: "This lovely 2 Bedroom Ras Asaylah townhouse is located near the marina and Kempinski Hotel/on Marina Drive. ",
    features: [
      {
        name: "bedrooms",
        qty: 2,
      },
      {
        name: "bathrooms",
        qty: 1,
      },
    ],
    price: " $650",
    flag: "For Sale",
  },
];
const BestFlatList = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState({
    itemsArray: [],
  });
  const getData = async () => {
    let res = await getProperty();
    setState((prev) => ({ ...prev, itemsArray: res }));
  };
  useEffect(() => {
    getData();
  }, []);
  const settings = {
    dots: fullScreen && true,
    infinite: true,
    speed: 500,
    slidesToShow: fullScreen ? 1 : 3,
    slidesToScroll: 1,
    prevArrow: <KeyboardDoubleArrowLeftIcon fontSize="large" />, // Custom left arrow icon
    nextArrow: <KeyboardDoubleArrowRightIcon fontSize="large" />,
  };
  return (
    <section className="section-best-estate">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Title title="Featured Properties" />
            <div className="row">
              <Slider {...settings}>
                {state.itemsArray.map((item) => (
                  <div className="col-lg-4 col-sm-12">
                    <BestFlatItem data={item} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BestFlatList;
