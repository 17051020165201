import React from "react";
import { Link } from "react-router-dom";
import MapComponent from "./googlemap";
const Footer = () => {
  const latitude = 23.556075; // Replace with your specific latitude
  const longitude = 58.368649; // Replace with your specific longitude

  const largeMapUrl = `https://www.openstreetmap.org/?mlat=${latitude}&mlon=${longitude}#map=14/${latitude}/${longitude}`;
  const handleEmailClick = () => {
    const recipient = "info@sevenstarsre.com"; // Replace with the recipient's email address
    const subject = "Subject of the email"; // Replace with the desired subject
    const body = "Content of the email"; // Replace with the desired email body

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };
  const handleNumberClick = (phoneNumber) => {
    window.open(`tel:${phoneNumber}`);
  };
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <i className="fas fa-home"></i>
            <span className="footer-other-text d-block mt-3 mb-3">
              Welcome to SSRE, an esteemed real estate company based in Oman. We
              specialize in the buying, selling, and renting of apartments,
              villas, and commercial properties, catering to the diverse needs
              and preferences of our clients.
            </span>
            <div className="footer-social">
              <div className="footer-social-item">
                <Link
                  to={
                    "https://www.facebook.com/people/Seven-Stars-Real-Estate/61554015402611/?mibextid=9R9pXO"
                  }
                  target="_blank">
                  <i className="fab fa-facebook"></i>
                </Link>
              </div>

              <div className="footer-social-item">
                {" "}
                <Link
                  to={"https://www.instagram.com/sevenstarsrealestate_/"}
                  target="_blank">
                  <i className="fab fa-instagram"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <p className="footer-title">Menu</p>
            <ul className="footer-ul">
              <li>
                <Link to="/" sx={{ textDecoration: "none" }}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/services">Our Service</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6">
            <p className="footer-title">Contact</p>
            <ul className="footer-ul">
              <li className="d-flex">
                <div className="footer-info-item">
                  <i className="fas fa-clock"></i>
                </div>{" "}
                <span>08:00-18:00</span>
              </li>
              <li className="d-flex">
                <div className="footer-info-item">
                  <i className="fas fa-envelope"></i>
                </div>{" "}
                <Link
                  style={{
                    m: 0,
                    p: 0,
                    textDecoration: "none",
                    color: "#ffffff8a",
                  }}
                  onClick={handleEmailClick}>
                  {" "}
                  info@sevenstarsre.com
                </Link>
                {/* <GmailComponent email="sevenstarsrealestate@yahoo.com" /> */}
              </li>
              <li className="d-flex align-items-center">
                <div className="footer-info-item">
                  <i className="fas fa-phone-alt"></i>
                </div>{" "}
                <div className="d-flex flex-column ">
                  <Link
                    style={{
                      m: 0,
                      p: 0,
                      textDecoration: "none",
                      color: "#ffffff8a",
                    }}
                    onClick={() => handleNumberClick("94330195")}>
                    {" "}
                    94330195
                  </Link>
                </div>
              </li>
              <li className="d-flex">
                <div className="footer-info-item">
                  <i className="fas fa-map-marker-alt"></i>
                </div>{" "}
                <span>
                Block no. 320, way no. 63, building no. 838, Al Hail North Muscat
                  {/* <a
                    href={largeMapUrl}
                    style={{ textDecoration: "none", color: "#ffffff8a" }}
                    target="_blank"
                    rel="noopener noreferrer">
                    Block 626 Way no 6208 Building no 1/524 Ghala
                    Industrial area Muscat{" "}
                  </a> */}
                </span>
              </li>
              {/* <MapComponent /> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
