import axios from "axios";

export const getProperty = async (userData) => {
  let res = await axios.get(
    `https://realstate-bice.vercel.app/api/property/getproperty`
  );
  let json = res.data.data;
  return json;
};
export const saveProperty = async (userData) => {
  let res = await axios.post(
    `https://realstate-bice.vercel.app/api/property/postproperty`,
    userData
  );
  let json = res.data;
  return json;
};
export const searchProperties = async (
  data,
  type,
  min,
  max,
  area,
  price,
  furnished
) => {
  let res = await axios.get(
    `https://realstate-bice.vercel.app/api/property/searchproperty?${data}${type}${min}${max}${area}${price}${furnished}`
  );
  let json = res.data.data;
  return json;
};
export const deleteProperty = async (propertyId) => {
  let res = await axios.delete(
    `https://realstate-bice.vercel.app/api/property/deleteproperty/${propertyId}`
  );
  let json = res.data;
  return json;
};
