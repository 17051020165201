import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogue from "../components/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { handleModal } from "../store/Slice";
import FlatItem from "./FlatItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import Textarea from "./Textarea";
import { deleteProperty, getProperty } from "../apiservices";
import DefaultImg from "../assets/muscat.jpg";
import { toast } from "react-toastify";

export const CustomField = styled(TextField)({
  mb: 2,
  minWidth: "250px",
  background: "#F6F6F6",
  "& label.Mui-focused": {
    color: "#000",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#12685d",
    },
  },
});
export const Layout = styled(Stack)({
  width: "100%",
  height: "64px",
  marginTop: "10px",

  borderRadius: "8px",
  background: "#12685d",
});
const defaultPin = "123-master-admin";
function Dashboard() {
  const { openModal } = useSelector((state) => state.slice);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    pin: "",
    openModal: false,
    images: [],
    itemsArray: [],
    features: [
      {
        name: "",
        quantity: 0,
      },
    ],
    title: "",
    saleType: "For Rent",
    price: 0,
    houseType: "",
    desc: "",
    city: "",
    address: "",
    displayImg: [],
    check: "save",
    id: "",
    deleteModal: false,
    deletedItem: {},
    furnished: "No",
    rooms: 0,
  });
  const getData = async () => {
    let res = await getProperty();
    setState((prev) => ({ ...prev, itemsArray: res }));
  };
  useEffect(() => {
    setState((prev) => ({ ...prev, displayImg: prev.itemsArray }));
    getData();
  }, []);

  const handlePinChange = (e) => {
    const { value } = e.target;
    setState((prev) => ({ ...prev, pin: value }));
    if (value === defaultPin) {
      dispatch(handleModal());
    }
  };
  const handleChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  const handleModals = (data = "", value = "") => {
    setState((prev) => ({
      ...prev,
      openModal: !prev.openModal,
      features: data?.id
        ? data.features
        : [
            {
              name: "",
              quantity: 2,
            },
          ],
      title: data?.id ? data.title : "",
      saleType: data?.id
        ? data.transaction === "sale"
          ? "For Sale"
          : "For Rent"
        : "For Sale",
      price: data?.id ? data.price : 0,
      houseType: data?.id ? data.housetype : "Town House",
      desc: data?.id ? data.description : "",
      city: data?.id ? data.area : "",
      address: data?.id ? data.address : "",
      images: data?.id ? data.images : [],
      displayImg: data?.id ? data.images : [],
      check: data?.id ? value : "save",
      rooms: data?.id ? data.rooms : 0,
      furnished: data?.id ? (data.furnished ? "Yes" : "No") : "No",
      id: data?.id ? data.id : "",
    }));
  };
  const addFeatures = () => {
    let arr = state.features;
    let obj = {
      id: Date.now(),
      name: "",
      quantity: "",
    };
    arr.push(obj);
    setState((prev) => ({ ...prev, features: arr }));
  };
  const removeFeature = (id) => {
    let arr = state.features;
    let filtered = arr.filter((item) => item._id !== id);
    setState((prev) => ({ ...prev, features: filtered }));
  };
  const handleFeaturesChange = (e, id) => {
    let { value } = e.target;
    let arr = [...state.features];
    arr.forEach((element) => {
      if (element.id === id) {
        element["name"] = value;
      }
    });
    setState((prev) => ({ ...prev, features: arr }));
  };
  const handleQty = (e, id) => {
    let { value } = e.target;
    let arr = state.features !== undefined ? state.features : [];
    arr.forEach((element) => {
      if (element.id === id) {
        element["quantity"] = value;
      }
    });
    setState((prev) => ({ ...prev, features: arr }));
  };
  const saveItem = async () => {
    if (
      state.title &&
      state.address &&
      state.city &&
      state.desc &&
      state.houseType &&
      state.images.length !== 0 &&
      state.features
    ) {
      var formdata = new FormData();
      formdata.append("title", state.title);
      formdata.append("description", state.desc);
      state.features.map((item) =>
        formdata.append("features[]", `${JSON.stringify(item)}`)
      );
      formdata.append(
        "transaction",
        state.saleType === "For Sale" ? "sale" : "rent"
      );
      formdata.append("price", state.price);
      formdata.append("housetype", state.houseType);
      formdata.append("address", state.address);
      formdata.append("rooms", state.rooms);
      formdata.append("furnished", state.furnished === "No" ? false : true);
      state.images.map((item) => formdata.append("images", item));

      formdata.append("area", state.city);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        "https://realstate-bice.vercel.app/api/property/postproperty",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          toast.success("Property Saved Successfully");
          handleModals();
          getData();
        })
        .catch((error) => toast.error("Something went wrong!"));
    } else {
      toast.error("Please fill out all fields!");
    }
  };
  const handleImg = (e) => {
    let array = state.displayImg;
    let arr = state.images;
    arr.push(e.target.files[0]);
    let makeUrl = URL.createObjectURL(e.target.files[0]);
    array.push({ img: makeUrl, id: new Date() });
    setState((prev) => ({ ...prev, images: arr, displayImg: array }));
  };
  const updateItem = async (id) => {
    if (
      state.title &&
      state.address &&
      state.city &&
      state.desc &&
      state.houseType &&
      state.images.length !== 0 &&
      state.features
    ) {
      var formdata = new FormData();
      formdata.append("title", state.title);
      formdata.append("description", state.desc);
      state.features.map((item) =>
        formdata.append("features[]", `${JSON.stringify(item)}`)
      );
      formdata.append(
        "transaction",
        state.saleType === "For Sale" ? "sale" : "rent"
      );
      formdata.append("price", state.price);
      formdata.append("housetype", state.houseType);
      formdata.append("address", state.address);
      formdata.append("rooms", state.rooms);
      formdata.append("furnished", state.furnished === "No" ? false : true);
      state.images.map((item) => formdata.append("images", item));
      formdata.append("area", state.city);

      var requestOptions = {
        method: "PUT",
        body: formdata,
        redirect: "follow",
      };
      fetch(
        `https://realstate-bice.vercel.app/api/property/updateproperty/${id}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          toast.success("Property Updated Successfully");
          handleModals();
          getData();
        })
        .catch((error) => console.log("error", error));
    } else {
      toast.error("Please fill out all fields!");
    }
  };

  const deleteCard = async (data) => {
    setState((prev) => ({
      ...prev,
      deleteModal: !prev.deleteModal,
      deletedItem: data,
    }));
  };
  const confirmDelete = async () => {
    let res = await deleteProperty(state.deletedItem.id);
    if (res) {
      toast.success(res.message);
      getData();
    } else {
      toast.success("something went wrong!");
    }
    setState((prev) => ({ ...prev, deleteModal: !prev.deleteModal }));
  };
  const handleDeleteModals = () => {
    setState((prev) => ({ ...prev, deleteModal: !prev.deleteModal }));
  };
  const handleDeleteImg = (id) => {
    if (id) {
      let arr = state.displayImg;
      let filtered = arr.filter((item) => item.id !== id);
      setState((prev) => ({ ...prev, displayImg: filtered }));
    }
  };
  return (
    <Stack sx={{ padding: 3 }}>
      {!openModal && (
        <>
          <Layout justifyContent="center" alignItems={"center"}>
            <Typography
              sx={{ color: "#FFF", fontSize: "24px", fontWeight: 700 }}>
              Admin Panel
            </Typography>
          </Layout>
          <Stack alignItems={"start"} justifyContent={"start"}>
            <Button
              sx={{
                width: "200px",
                m: 2,
                textTransform: "none",
                background: "#12685d",
                color: "#FFF",
                "&:hover": {
                  background: "#12685d",
                },
              }}
              onClick={handleModals}>
              Add Items
            </Button>
          </Stack>
          <Grid
            container
            sx={{ mt: 5, padding: 3 }}
            justifyContent="center"
            alignItems={"center"}>
            <Grid container md={12}>
              {state.itemsArray && state.itemsArray.length !== 0 ? (
                state.itemsArray.map((item) => (
                  <FlatItem
                    slug="lorem-ipsum-1"
                    data={item ? item : DefaultImg}
                    handleModal={(data) => handleModals(data, "update")}
                    deleteCard={(data) => deleteCard(data)}
                    handleDeleteModals={handleDeleteModals}
                    deleteModal={state.deleteModal}
                  />
                ))
              ) : (
                <div className="no-records d-flex justify-content-center alig-items-center">
                  <p>No Records to Display Example</p>
                </div>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <CustomDialogue
        open={openModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              sx={{ color: "#12685d", fontWeight: 600, fontSize: "18px" }}>
              Login First
            </Typography>
          </Stack>
        }
        content={
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ padding: "20px", paddingLeft: "60px", paddingRight: "60px" }}>
            <CustomField
              fullWidth
              id="outlined-select-currency"
              type="password"
              value={state.pin}
              onChange={handlePinChange}
              label="Enter Pin"
              sx={{
                mb: 2,
                background: "#F6F6F6",
                width: "400px",
              }}
            />
          </Stack>
        }
      />
      <CustomDialogue
        open={state.openModal}
        handleClose={handleModals}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography
              sx={{ fontWeight: 900, fontSize: "18px", color: "#12685d" }}>
              {state.check ? "Update Item" : "Add new Item"}
            </Typography>
          </Stack>
        }
        content={
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ padding: "20px", paddingLeft: "60px", paddingRight: "60px" }}>
            <Stack>
              <Button
                size="small"
                className="btn-search"
                component="label"
                sx={{
                  width: "200px",
                  m: 2,
                  textTransform: "none",
                  background: "#12685d",
                  color: "#FFF",
                  "&:hover": {
                    background: "#12685d",
                  },
                }}>
                Upload File
                <input type="file" hidden onChange={handleImg} />
              </Button>
              <Stack direction={"row"} spacing={2} sx={{ mb: 2 }}>
                <Grid container>
                  {state.displayImg &&
                    state.displayImg.map((item) => (
                      <Grid>
                        <img
                          onClick={() => handleDeleteImg(item?.id)}
                          src={item.img || item}
                          alt="images"
                          height={"50px"}
                          className="rounded"
                          width={"50px"}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <CustomField
                id="outlined-select-currency"
                label="Title"
                value={state.title}
                name="title"
                onChange={handleChange}
                sx={{
                  mb: 2,
                  background: "#F6F6F6",
                  width: "250px",
                }}
              />
              <CustomField
                fullWidth
                id="outlined-select-currency"
                select
                label="Select Sale Type"
                value={state.saleType}
                name="saleType"
                onChange={handleChange}
                sx={{ mb: 2 }}>
                {["For Sale", "For Rent"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="Rooms"
                type="number"
                value={state.rooms}
                name="rooms"
                onChange={handleChange}
                sx={{ mb: 2 }}></CustomField>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                select
                label="Furnished"
                value={state.furnished}
                name="furnished"
                onChange={handleChange}
                sx={{ mb: 2 }}>
                {["Yes", "No"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ mb: 1 }}
              gap={2}>
              <Stack direction={"row"} sx={{ width: "250px" }}>
                <Typography sx={{ fontWeight: 500 }}>Add Features</Typography>
                <Button onClick={addFeatures}>
                  <AddCircleIcon sx={{ color: "#12685d" }} />
                </Button>
              </Stack>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="Area"
                value={state.city}
                name="city"
                onChange={handleChange}
                sx={{
                  mb: 2,
                  background: "#F6F6F6",
                  width: "250px",
                }}
              />
            </Stack>
            {state.features &&
              state.features.map((item, key) => (
                <Stack
                  key={key}
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={2}
                  sx={{ marginLeft: 3 }}>
                  <CustomField
                    fullWidth
                    id="outlined-select-currency"
                    label="Name"
                    placeholder="i.e Kitchen"
                    value={item.name}
                    onChange={(e) => handleFeaturesChange(e, item.id)}
                    sx={{
                      mb: 2,
                      background: "#F6F6F6",
                      width: "250px",
                    }}
                  />
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <CustomField
                      fullWidth
                      value={item.quantity}
                      type="number"
                      placeholder="i.e 2"
                      onChange={(e) => handleQty(e, item.id)}
                      id="outlined-select-currency"
                      label="Quantity"
                      sx={{
                        mb: 2,
                        background: "#F6F6F6",
                        width: "250px",
                      }}
                    />
                    <DeleteIcon
                      sx={{ color: "red" }}
                      onClick={() => removeFeature(item._id)}
                    />
                  </Stack>
                </Stack>
              ))}

            <Stack direction={"row"} gap={2}>
              <CustomField
                fullWidth
                id="outlined-select-currency"
                label="Price"
                type="number"
                value={state.price}
                name="price"
                onChange={handleChange}
                sx={{
                  mb: 2,
                  background: "#F6F6F6",
                  width: "250px",
                }}
              />
              <CustomField
                fullWidth
                id="outlined-select-currency"
                select
                label="Select House Type"
                value={state.houseType}
                name="houseType"
                onChange={handleChange}
                sx={{ mb: 2 }}>
                {[
                  "Town House",
                  "Pent House",
                  "Apartment",
                  "Showroom",
                  "Commercial Space",
                  "Villa",
                  "Land",
                  "Building",
                ].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </CustomField>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <Textarea
                aria-label="minimum height"
                minRows={3}
                value={state.desc}
                name="desc"
                onChange={handleChange}
                sx={{ mb: 3 }}
                placeholder="Item  Description"
              />
              <Textarea
                aria-label="minimum height"
                minRows={3}
                value={state.address}
                name="address"
                onChange={handleChange}
                sx={{ mb: 3 }}
                placeholder="Address"
              />
            </Stack>

            <Stack spacing={3} direction={"row"}>
              <Button
                onClick={handleModals}
                size="small"
                sx={{
                  background: "#FFF",
                  width: "200px",
                  textTransform: "none",

                  color: "#9F9E9E",
                  "&:hover": {
                    background: "#EFEFEF",
                  },
                }}>
                Cancel
              </Button>
              <Button
                onClick={
                  state.check === "save" ? saveItem : () => updateItem(state.id)
                }
                size="small"
                sx={{
                  width: "200px",
                  textTransform: "none",
                  background: "#12685d",
                  color: "#FFF",
                  "&:hover": {
                    background: "#12685d",
                  },
                }}>
                {state.check !== "save" ? "Update" : "Save"}
              </Button>
            </Stack>
          </Stack>
        }
      />
      <CustomDialogue
        open={state.deleteModal}
        onClose={handleDeleteModals}
        content={
          <Stack sx={{ padding: 2 }} spacing={2}>
            <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
              Are you sure you want to delete?
            </Typography>
            <Stack justifyContent={"center"} direction={"row"}>
              <Button
                sx={{
                  m: 2,
                  textTransform: "none",
                  background: "#12685d",
                  color: "#FFF",
                  "&:hover": {
                    background: "#12685d",
                  },
                }}
                onClick={confirmDelete}>
                Confirm
              </Button>{" "}
              <Button
                sx={{
                  m: 2,
                  textTransform: "none",
                  background: "grey",
                  color: "#FFF",
                  "&:hover": {
                    background: "grey",
                  },
                }}
                onClick={handleDeleteModals}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        }></CustomDialogue>
    </Stack>
  );
}

export default Dashboard;
