import Banner from "./Banner";
import React from "react";
import TeamList from "./userPanel/cities/TeamList";
import BestFlatList from "./BestFlatList";
import bannerImg from "../banner.jpg";
import Intro from "./userPanel/Intro";

const Home = () => {
  return (
    <React.Fragment>
      <Banner banner={bannerImg} />
      <Intro />
      <BestFlatList />
      {/* <TeamList /> */}
    </React.Fragment>
  );
};

export default Home;
