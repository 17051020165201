import React from "react";
import House from "../../assets/muscat.jpg";
import Title from "../Title";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
function Intro() {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="p-5">
      <Title title="Introduction" />
      <div class="row p-2">
        <div class="col-lg-7">
          <p class="about-text">
            Seven Stars Real Estate(SSRE) is a premier real estate
            company located in the vibrant city of Muscat. We specialize in
            offering a wide range of properties, catering to the diverse needs
            and preferences of both local and international clients. With our
            commitment to excellence, personalized service, and high-quality
            properties, Seven Stars Real Estate has become a trusted name in the
            real estate industry.
          </p>
          <p class="about-text">
            We invite you to explore our website and discover the exceptional
            real estate opportunities available in Oman. Whether you are a
            seasoned investor, a first-time buyer, or a business owner seeking
            commercial spaces, SSRE is here to assist you in finding the perfect
            property that matches your aspirations and goals.
          </p>
          <p class="about-text">
            Contact us today, and let us be your trusted partner in your real
            estate journey. The key to unlocking your dreams awaits at SSRE.
          </p>
        </div>
        <div class="col-lg-5">
          <img
            src={House}
            alt=""
            height={fullScreen ? "200" : "340"}
            class="rounded"
          />
        </div>
      </div>
    </div>
  );
}

export default Intro;
