import Villa from "../../assets/greenLogo.jpg";
const About = () => {
  return (
    <section className="about">
      <div className="page-top-about">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex justify-content-center align-items-center"
              style={{ height: 600 }}>
              <h1
                className="page-title"
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "50px",
                  textShadow: "0px 4px 10px #000",
                }}>
                ABOUT US
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <p className="about-text">
            Welcome to Seven Stars Real Estate(SSRE), an esteemed real estate
            company based in Oman. We specialize in the buying, selling, and
            renting of properties, catering to the diverse needs and preferences
            of our clients.
          </p>
          <div className="row  align-items-center">
            <div className="col-lg-6">
              <div className="about-text">
                <p>
                  At SSRE, we understand that finding the perfect property can
                  be both exciting and challenging. That is why our dedicated
                  team of experienced professionals is committed to providing
                  you with personalized and efficient services throughout your
                  real estate journey.
                </p>
                <p>
                  Whether you are looking to invest in a new property, search
                  for your dream home, or explore lucrative commercial
                  opportunities, we have a wide range of listings that cater to
                  various budgets and requirements. Our extensive portfolio
                  includes a diverse selection of high-quality residential and
                  commercial properties in sought-after locations across Oman.
                </p>
                <p>
                  We pride ourselves on our strong market knowledge and a
                  comprehensive understanding of the local real estate
                  landscape. This enables us to provide our clients with
                  accurate and up-to-date information, empowering them to make
                  well-informed decisions.
                </p>
                <p>
                  We invite you to explore our website and discover the
                  exceptional real estate opportunities available in Oman.
                  Whether you are a seasoned investor, a first-time buyer, or a
                  business owner seeking commercial spaces, SSRE is here to
                  assist you in finding the perfect property that matches your
                  aspirations and goals.
                </p>
                <p>
                  Contact us today, and let us be your trusted partner in your
                  real estate journey. The key to unlocking your
                  dreams awaits at SSRE.
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img src={Villa} alt="product" className="w-100 " />
              {/* <img src={AboutImg} alt="product" className="w-100 m-2" /> */}
              {/* <img src={Villa} alt="product" className="w-75" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
