import DeleteIcon from "@mui/icons-material/Delete";
import DefaultImg from "../assets/muscat.jpg";
import { Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
const FlatItem = ({
  slug,
  data,
  handleModal,
  deleteCard,
  handleDeleteModals,
  deleteModal,
}) => {
  return (
    <div className="text-center col-lg-3 col-12 col-md-6 ">
      <div className="item m-1">
        <div className="best-estate-img-area">
          <img
            className="best-estate-img rounded"
            src={data.images ? data.images[0] : DefaultImg}
            alt="flat"
            height={"230px"}
            width={"300px"}
          />
          <div
            className={`best-estate-state ${
              data.transaction === "rent" ? "bg-green" : "bg-red"
            }`}>
            {data.transaction === "rent" ? "For Rent" : "For Sale"}
          </div>
        </div>
        <div className="item-description">
          <div className="d-flex justify-content-between mb-3">
            <span className="item-title">{data.title}</span>
            <span className="item-price fw-bold">OMR {data.price}</span>
          </div>
          <div className="item-icon d-flex alig-items-center justify-content-between">
            {data.features.map(
              (item, id) =>
                id < 2 && (
                  <div className="d-flex alig-items-center ">
                    <i className="fas fa-check-circle"></i>
                    <span className="mx-1">{item.quantity}</span>
                    <span> {item.name}</span>
                  </div>
                )
            )}
            <span className="item-price ">
              Furnished:
              {data.furnished === false ? "No" : "Yes"}
            </span>
          </div>
          <Stack direction={"row"}>
            <button
              className="btn btn-detail btn-sm"
              onClick={() => handleModal(data)}>
              <EditIcon fontSize="small" />
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteCard(data)}>
              <DeleteIcon fontSize="small" />
            </button>
          </Stack>

          {/* <div>
              <i class="fas fa-solid fa-fire-burner"></i>
              <span> 1 Kitchen</span>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default FlatItem;
