import React, { useEffect, useState } from "react";
import Card from "./Card";
import Banner from "./Banner";
import BannerImg from "../../assets/header.jpg";
import { searchProperties } from "../../apiservices";
function SearchData() {
  const [state, setState] = useState({
    propertyArr: [],
  });
  const searchProperty = async (
    tran = "",
    type = "",
    min = "",
    max = "",
    price = "",
    area = "",
    furnished = ""
  ) => {
    let res = await searchProperties(
      tran,
      type,
      min,
      max,
      price,
      area,
      furnished
    );
    if (res) {
      setState((prev) => ({ ...prev, propertyArr: res }));
    }
  };
  useEffect(() => {
    searchProperty();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Banner
        banner={BannerImg}
        search={(data, type, min, max, price, area, furnished) =>
          searchProperty(data, type, min, max, price, area, furnished)
        }
      />
      <Card array={state.propertyArr} />
    </div>
  );
}

export default SearchData;
