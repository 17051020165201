import "./App.css";
import FlatDetail from "./components/FlatDetail";
import Header from "./components/Header";
import Footer from "./components/userPanel/footer/Footer";
import Home from "./components/Home";
import Contact from "./components/userPanel/Contact";
import About from "./components/userPanel/About";
import Services from "./components/userPanel/Services";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SearchData from "./components/search/SearchData";
import AdminPanel from "./adminPanel";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Logo from "./assets/logos.png";
function App() {
  const { openModal } = useSelector((state) => state.slice);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous process (e.g., fetching data or other setup)
    // Replace this with your actual loading logic (e.g., fetching data or initializing resources)
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after a certain time (simulating the loading process)
    }, 2000); // Simulating a 2-second loading time (adjust as needed)

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);
  return (
    <>
      {loading ? (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "100vh" }}>
          <div>
            <img
              src={Logo}
              alt="Material-UI Logo"
              width="220"
              height="220"
              class="rounded-lg"
            />
          </div>
          <Typography variant="h5" gutterBottom>
            Loading...
          </Typography>
          <Stack>
            <CircularProgress color="success" />
          </Stack>
        </Stack>
      ) : (
        <BrowserRouter>
          <div className="App">
            {openModal && <Header />}

            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route path="/search" element={<SearchData />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/services" element={<Services />}></Route>
              <Route path="/flat_details" element={<FlatDetail />}></Route>
              <Route path="/master_admin" element={<AdminPanel />}></Route>
            </Routes>
            {openModal && <Footer />}
          </div>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
