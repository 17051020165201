import { useNavigate } from "react-router-dom";

const BestFlatItem = ({ flatState, data }) => {
  const navigate = useNavigate();
  const openDetails = () => {
    navigate("/flat_details", { state: { data: data } });
  };
  return (
    <div className="best-estate">
      <div className="best-estate-item" onClick={openDetails}>
        <div className="best-estate-img-area">
          <img
            className="best-estate-img"
            src={data.images[0]}
            alt="flat"
            height={"300"}
            width={"300"}
          />
          <div
            className={`best-estate-state ${
              data.transaction === "rent" ? "bg-green" : "bg-red"
            }`}>
            {data.transaction === "rent" ? "For Rent" : "For Sale"}
          </div>
        </div>
        <div className="best-estate-content">
          <h4>{data.title}</h4>
          <span>{data.desc}</span>
        </div>
        <div className="best-estate-features">
          <div className="d-flex">
            {data.features.map(
              (item, index) =>
                index <= 1 && (
                  <div className="best-estate-feature">
                    <i className="fas fa-check-circle"></i>
                    <span>
                      {item.qty} {item.name}
                    </span>
                  </div>
                )
            )}
          </div>
          <h5 className="best-estate-price">OMR {data.price}</h5>
        </div>
      </div>
    </div>
  );
};

export default BestFlatItem;
