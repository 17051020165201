import { Stack, styled } from "@mui/material";
import Villa from "../../assets/keys.jpg";
export const StyledStack = styled(Stack)({
  borderRadius: "8px",
  // boxShadow: "rgb(77, 175, 81)  1px 1px 1px 1px",
  padding: 4,
  border: " 0px 0px 1px 0px",
  background: "#fff",
});
const Services = () => {
  return (
    <section className="about">
      <div className="page-top-services">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 d-flex justify-content-center align-items-center"
              style={{ height: 600 }}>
              <h1
                className="page-title"
                style={{
                  color: "#fff",
                  textShadow: "0px 4px 10px #000",
                  fontWeight: "bold",
                }}>
                OUR SERVICES
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div className="container">
          <div className="  align-items-center d-flex justify-content-center">
            <div className="col-lg-8 ">
              <div>
                <StyledStack justifyContent={"center"} alignItems={"center"}>
                  {/* <img src={Villa} alt="service" height={"300"} /> */}

                  <p className="about-text p-2 px-5">
                    At Seven Stars Real Estates, our primary focus is to provide
                    hassle-free and convenient solutions for those seeking
                    properties in Oman. Whether you're searching for a luxurious
                    apartment, a spacious villa, or a commercial shop, we have
                    an extensive portfolio that can meet your requirements. Our
                    dedicated team works diligently to facilitate a seamless
                    renting process for our clients, offering personalized
                    assistance at every step, thus ensuring that they find
                    their ideal property.
                  </p>
                </StyledStack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
