import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const Banner = ({ banner, search }) => {
  const location = useLocation();
  const [state, setState] = useState({
    filter: location.state?.filter ? location.state.filter : "",
    houseType: location.state?.type ? location.state.type : "",
    minRooms: location.state?.min ? location.state.min : "",
    maxRooms: location.state?.max ? location.state.max : "",
    area: location.state?.city ? location.state.city : "",
    price: location.state?.price ? location.state.price : "",
  });
  console.log(location.state);
  const filterBar = (input) => {
    setState((prev) => ({ ...prev, filter: input }));
  };
  const handleChange = (e) => {
    let { name } = e.target;
    let { value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    Filter();
  }, []);

  const Filter = () => {
    // e.preventDefault();
    let transaction =
      state.filter &&
      `transaction=${state.filter === "For Sale" ? "sale" : "rent"}`;
    let house = state.area && `&area=${state.area}`;
    let min = state.minRooms && `&minrooms=${state.minRooms}`;
    let max = state.maxRooms && `&maxrooms=${state.maxRooms}`;
    let price = state.price && `&price=${state.price}`;
    let area = state.houseType && `&housetype=${state.houseType}`;
    let furnished = state.furnished && `&furnished=${state.furnished}`;
    search(transaction, house, min, max, price, area, furnished);
  };
  return (
    <div
      className="banner d-flex align-items-center"
      style={{ backgroundImage: `url(${banner})` }}>
      <div className="bg-custom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="banner-area text-center pt-4 pb-4">
                <div class="banner-tab-wrap searchbar ">
                  <ul class="nav mb-3" role="tablist">
                    <li class="" role="presentation">
                      <button
                        class="btn  btn-sm "
                        style={{
                          background: state.filter == "For Rent" && "#12685d",
                          color: state.filter == "For Rent" && "#fff",
                          borderBottom: "1px solid #fff",
                          borderRight: "1px solid #fff",
                        }}
                        data-bs-toggle="pill"
                        data-bs-target="#rent"
                        type="button"
                        role="tab"
                        aria-selected="true"
                        onClick={() => filterBar("For Rent")}>
                        Rent
                      </button>
                    </li>
                    <li class="" role="presentation">
                      <button
                        class="btn  btn-sm "
                        style={{
                          background: state.filter === "For Sale" && "#12685d",
                          color: state.filter == "For Sale" && "#fff",
                          borderBottom: "1px solid #fff",
                          borderRight: "1px solid #fff",
                        }}
                        data-bs-toggle="pill"
                        data-bs-target="#buy"
                        type="button"
                        role="tab"
                        onClick={() => filterBar("For Sale")}
                        aria-selected="false">
                        Sale
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content" id="pills-tabContent">
                    <div id="rent" role="tabpanel">
                      <form class="form-wrap">
                        <div class="row align-items-end">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-6 col-lg-4">
                                <div class=" mb-lg-0">
                                  <label class="form-label  m-0">
                                    Location
                                  </label>

                                  <select
                                    class="form-control  m-0"
                                    onChange={(e) => handleChange(e)}
                                    name="area"
                                    value={state.area}>
                                    <option>Select</option>
                                    {[
                                      "Barka",
                                      "Rumais",
                                      "Al khoud",
                                      "Al maabilah",
                                      "Al mawaleh",
                                      "Muscat bay",
                                      "Al hail",
                                      "Al mouj",
                                      "Muscat hills",
                                      "Azaiba",
                                      "Ghala",
                                      "Ghubrah",
                                      "Bawshar",
                                      "Al khuwair",
                                      "Madinat Sultan Qaboos",
                                      "Madinat Al Alam",
                                      "Qurum",
                                      "Darsait",
                                      "Ruwi",
                                      "Wadi kabeer",
                                      "Al amarat",
                                      "Sifah",
                                      "Misfah",
                                      "Russayl",
                                      "Yiti"
                                    ].map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4 ">
                                <div class="form-group mb-lg-0">
                                  <label class="form-label  m-0">
                                    Property Type
                                  </label>
                                  <select
                                    class="form-control"
                                    onChange={handleChange}
                                    name="houseType"
                                    value={state.houseType}>
                                    <option>Select</option>

                                    {[
                                      "Town House",
                                      "Pent House",
                                      "Apartment",
                                      "Showroom",
                                      "Commercial Space",
                                      "Villa",
                                      "Land",
                                      "Building",
                                    ].map((option) => (
                                      <option value={option}>{option}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4">
                                <div class="form-group mb-md-0">
                                  <label class="form-label m-0">
                                    Min Rooms
                                  </label>
                                  <select
                                    class="form-control"
                                    onChange={handleChange}
                                    name="minRooms"
                                    value={state.minRooms}>
                                    <option>Minimum</option>
                                    {[1, 2, 3, 4, 5].map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4">
                                <div class="form-group mb-md-0">
                                  <label class="form-label  m-0">
                                    Max Rooms
                                  </label>
                                  <select
                                    class="form-control"
                                    onChange={handleChange}
                                    name="maxRooms"
                                    value={state.maxRooms}>
                                    <option>Maximum</option>
                                    {[6, 7, 8, 9, 10, "above 10"].map(
                                      (item) => (
                                        <option value={item}>{item}</option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>

                              <div class="col-md-6 col-lg-4">
                                <div class="form-group mb-md-0">
                                  <label class="form-label  m-0">
                                    Max Price
                                  </label>
                                  <select
                                    class="form-control"
                                    onChange={handleChange}
                                    name="price"
                                    value={state.price}>
                                    <option>Select</option>
                                    {[
                                      "below 100",
                                      100,
                                      200,
                                      300,
                                      400,
                                      500,
                                      600,
                                      700,
                                      800,
                                      900,
                                      1000,
                                      "above 1000",
                                    ].map((item) => (
                                      <option value={item}>OMR {item}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4">
                                <div class="form-group mb-md-0">
                                  <label class="form-label  m-0">
                                    Furnished
                                  </label>
                                  <select
                                    class="form-control"
                                    onChange={handleChange}
                                    name="furnished"
                                    value={state.furnished}>
                                    <option>Select </option>

                                    {["Yes", "No"].map((item) => (
                                      <option value={item}> {item}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-12 col-lg-12  mt-4">
                                <Button
                                  class="btn btn-search btn-icon ms-auto"
                                  onClick={Filter}>
                                  Search
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
