import React from "react";
import { useNavigate } from "react-router-dom";
function Card({ array }) {
  const navigate = useNavigate();

  const openDetails = (data) => {
    navigate("/flat_details", { state: { data: data } });
  };
  return (
    <div class="container mt-5">
      <h1 class="m-5 font-weight-bold">Featured Properties</h1>
      {array.map((item) => (
        <>
          <div class="col-11  align-items-center">
            <div
              class="row rounded p-2"
              style={{
                background: "#FFF",
                boxShadow: "rgb(77, 175, 81) 0px 1px 1px",
              }}>
              <div class="col">
                <img
                  src={item.images[0]}
                  alt="productImg"
                  height={"220px"}
                  width={"220px"}
                  className="rounded "
                />
              </div>
              <div class="col-9">
                <div>
                  <p class="font-weight-bold h4" style={{ color: "#12685d" }}>
                    OMR {item.price}
                  </p>
                  <div>
                    <h4>{item.title}</h4>
                    <p style={{ color: "#9F9E9E" }}>{item.description}</p>
                  </div>
                  <div class="d-flex flex-row">
                    {item.features.map((info) => (
                      <p class="mx-1">
                        {info.quantity} x {info.name}
                      </p>
                    ))}
                  </div>
                  <div class="d-flex justify-content-end m-2">
                    <button
                      onClick={() => openDetails(item)}
                      class="btn btn-sm "
                      style={{ background: "#12685d", color: "#F6F6F6" }}>
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </>
      ))}
    </div>
  );
}

export default Card;
